import React from "react";
import { motion } from "framer-motion";
import { LoadingImage } from "../../components";
import { loadingContainer, loadingItem, loadingItemMain } from "../../theme";

const Loading = ({ setLoading }) => {
  return (
    <motion.div className="loader">
      <motion.div
        variants={loadingContainer}
        onAnimationComplete={() => setLoading(false)}
        initial="hidden"
        animate="show"
        exit="exit"
        className="loader-inner"
      >
        <LoadingImageBlock variants={loadingItem} id="br-1" />
        <motion.div variants={loadingItemMain} className="transition-image">
          <motion.img
            layoutId="main-image-1"
            src={process.env.PUBLIC_URL + `/images/br-4.jpeg`}
          />
        </motion.div>
        <LoadingImageBlock variants={loadingItem} id="br-2" />
        <LoadingImageBlock variants={loadingItem} id="br-3" />
        <LoadingImageBlock variants={loadingItem} id="br-5" />
      </motion.div>
    </motion.div>
  );
};

const LoadingImageBlock = ({ posX, posY, variants, id }) => {
  return (
    <motion.div
      variants={variants}
      className={`image-block ${id}`}
      style={{
        top: `${posY}vh`,
        left: `${posX}vw `,
      }}
    >
      <LoadingImage
        src={process.env.PUBLIC_URL + `/images/${id}.webp`}
        fallback={process.env.PUBLIC_URL + `/images/${id}.jpg`}
        alt={id}
      />
    </motion.div>
  );
};

export default Loading;
