import React from "react";
import { motion } from "framer-motion";
import { useMousePosition } from "../../utils";

const Cursor = ({ cursorHovered }) => {
  const { x, y } = useMousePosition();

  return (
    <motion.div
      animate={{
        x: x - 16,
        y: y - 16,
        scale: cursorHovered ? 1.2 : 1,
        opacity: cursorHovered ? 0.8 : 0,
      }}
      transition={{
        ease: "linear",
        duration: 0.2,
      }}
      className="cursor"
    ></motion.div>
  );
};

export default Cursor;
