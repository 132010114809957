import React from "react";
import { motion } from "framer-motion";
import Banner from "../../components/banner/Banner";

const UnderConstruction = (props) => {
  return (
    <>
      <Banner {...props} />
      <div className="transition-image final">
        <motion.img
          transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}
          src={process.env.PUBLIC_URL + `/images/br-4.jpeg`}
          layoutId="main-image-1"
        />
        <motion.div
          className="mobile-coming-soon"
          initial={{ opacity: 0, y: 180 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.6,
          }}
        >
          <p>Coming Soon</p>
          <div className="mobile-coming-soon-link">
            <p>
              Say hi:
              <span>
                <a href="mailto:bill.dcanis@gmail.com">hey@brantfordrealty</a>
              </span>
            </p>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default UnderConstruction;
