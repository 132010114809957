import React, { useState, useEffect } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import { useMousePosition } from "./utils";
import { Cursor, Footer } from "./components";
import {
  About,
  // Home,
  Contact,
  Loading,
  PageNotFound,
  PrivacyStatement,
  TOS,
  UnderConstruction,
} from "./pages";
import "./theme/_main.scss";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [cursorHovered, setCursorHovered] = useState(false);
  const { x, y } = useMousePosition();

  // Loading Handler
  useEffect(() => {
    isLoading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
  }, [isLoading]);

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <AnimateSharedLayout type="crossfade">
      <AnimatePresence>
        {!isLoading ? (
          <div className="App">
            <Cursor cursorHovered={cursorHovered} />
            <Routes>
              {/* Under Construction */}
              <Route
                path="/"
                element={
                  <UnderConstruction setCursorHovered={setCursorHovered} />
                }
              />

              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/About" element={<About />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/Privacy-statement" element={<PrivacyStatement />} />
              <Route path="/Terms-of-service" element={<TOS />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            {/* <Footer /> */}
          </div>
        ) : (
          <motion.div key="loader">
            <Loading setLoading={setIsLoading} />
          </motion.div>
        )}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
}

export default App;
