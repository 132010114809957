import { useState, useEffect } from "react";

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  useEffect(() => {
    const handlePosition = (e: any) => {
      setMousePosition({ x: e.pageX, y: e.pageY });
    };

    window.addEventListener("mouseover", handlePosition);
    return () => window.removeEventListener("mouseover", handlePosition);
  }, []);

  return mousePosition;
};
