export const navData = [
  {
    id: "About",
    name: "About",
    leftLineFlex: 0,
    rightLineFlex: 1,
    thumbnailPosition: 600,
    offest: 200,
    src: 1,
  },
  {
    id: "Work",
    name: "Work",
    leftLineFlex: 3,
    rightLineFlex: 1,
    thumbnailPosition: 200,
    offest: -500,
    src: 2,
  },
  {
    id: "Contact",
    name: "Contact",
    leftLineFlex: 1,
    rightLineFlex: 3,
    thumbnailPosition: 1900,
    offest: 300,
    src: 3,
  },
];
